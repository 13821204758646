import { __decorate } from "tslib";
// Watch
import { Component, Vue } from 'vue-property-decorator';
import { documentoFiscalStore, mainStore, sefazStore } from '@/store';
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Nome',
                sortable: true,
                value: 'nome',
                align: 'left',
            },
            {
                text: 'Capacidade',
                sortable: true,
                value: 'capacidade',
                align: 'left',
            },
            {
                text: 'Ações',
                value: 'actions',
            },
        ];
        this.loading = true;
        this.search = '';
        this.selectedItem = {};
        this.showConfigStepper = false;
        this.statusCertificado = null;
        this.sefazIntegracaoConfigurada = false;
        this.e1 = 1;
        this.load = {};
        this.importacaoHeaders = [
            {
                text: 'CNPJ',
                sortable: true,
                value: 'cnpj',
                align: 'left',
            },
            {
                text: 'Chave NFe',
                sortable: true,
                value: 'chave_nfe',
                align: 'left',
            },
            {
                text: 'Evento',
                sortable: true,
                value: 'evento',
                align: 'left',
            },
            {
                text: 'Dia/Hora',
                sortable: true,
                value: 'evento_dia_hora',
                align: 'left',
            },
            {
                text: 'Ações',
                sortable: true,
                value: 'actions',
                align: 'left',
            },
        ];
        this.importacaoItems = [];
        this.textDescriptionOptions = [
            'Saiba todas as Notas Fiscais que foram emitidas para o CNPJ da sua empresa',
            'Crie compras a partir das Notas Fiscais importadas',
            'As notas baixadas não mesclam com suas notas de documentoFiscal, pode baixar à vontade!',
        ];
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    get dadosCadastroSefaz() {
        return sefazStore.dadosCadastro;
    }
    get compras() {
        return documentoFiscalStore.documentosFiscais;
    }
    async testarConexaoSefaz() {
        this.load = await sefazStore.getDadosCadastro();
        const successNotification = {
            content: 'Integração configurada com sucesso!',
            color: 'success',
        };
        mainStore.addNotification(successNotification);
        // this.load = true;
    }
    async mounted() {
        this.loading = true;
        this.sefazIntegracaoConfigurada =
            this.userProfile.company.sefaz_cnae != null;
        if (this.sefazIntegracaoConfigurada) {
            this.importacaoItems = await sefazStore.getConsultaDistribuicao();
        }
        this.loading = false;
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-compras-edit', params: { id } });
    }
};
List = __decorate([
    Component
], List);
export default List;
